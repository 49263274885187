.privacy-component {
    text-align: center;
    color: white;
    padding: 0 50px;
}

.privacy-component-statement {
    max-width: 500px;
    display: inline-block;
    white-space: pre-line;
}