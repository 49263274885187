.header-component {
    display: flex;
    flex-direction: column;
    box-shadow: 0 9px 13px rgb(0 0 0 / 5%);
    background-color: black;
    color: white;
    font-family: Righteous, sans-serif;
}

.header-component h1 {
    font-size: 28px;
    font-weight: 700;

    margin-top: 0;
}

.header-component .brand {
    margin-top: 10px;
    color: white;
}

.header-component .menu-spacer {
    margin-left: 15px;
}

.header-component h1:hover,
.header-component a:hover {
    text-shadow: 1px 1px 5px lightgray;
    color: white;
}


.header-component a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.header-component .menu {
    display: flex;
    width: 100%;
    justify-content: center;

}

.header-component .menu h1 {
    position: relative;
    font-size: 24px;
    font-weight: lighter;;
}
@media (min-width: 500px) {
    .header-component {
        flex-direction: row;
        align-items: flex-start;
        padding: 0 50px;
    }

    .header-component .menu {
        width: auto;
        justify-content: flex-end;
        flex-grow: 1;
        height: 100%;
        margin-top: 10px;
    }

    .header-component .menu h1 {
        font-size: 28px;
        font-weight: 700;

    }
}