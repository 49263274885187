.shoe-health-component {
}
.shoe-health-component-feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 32px 10px;
    padding: 32px 20px;
}

.shoe-health-component-feature-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}