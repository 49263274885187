.featured-app-component {
    padding: 30px;
    text-decoration: none;
    font-weight: lighter;
}

.featured-app-component img {
    width: 150px;
}

.learn-more {
    text-decoration: none;
    color: white;
    font-weight: normal;
}
.learn-more:hover {
    text-shadow: 1px 1px 5px lightgray;
}