.background-component {
    text-align: center;
    color: white;
    background-image: url("../images/forest.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
.background-component-overlay {
    background-color: black;
    opacity: 85%;
    position: absolute;
    height: 100vh;
    width: 100vw;
}