.support-component {
    text-align: center;
    color: white;
    padding: 0 50px;
}

.support-component-statement {
    max-width: 500px;
    display: inline-block;
    white-space: pre-line;
}

.support-component-mail-link {
    text-decoration: none;
    color: white;
}

.support-component-mail-link:hover{
    text-shadow: 1px 1px 5px lightgray;
}