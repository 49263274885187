.dropdown-component {
    position: relative;
    display: inline-block;
    float: right;
}
.dropdown-button {

}
.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;
    color: gray;
    background-color: black;
    min-width: 130px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    text-align: right;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 8px;
    opacity: 80%;
}

.dropdown-content a {
    display: block;
    text-align: right;
    justify-content: flex-end;
    color: gray;
    font-size: 20px;
}
.dropdown-component:hover .dropdown-content{display: block;}